/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video': {
    width: 16,
    height: 11,
    viewBox: '0 0 16 11',
    data: '<path pid="0" d="M11.636 6.52l2.91 1.94V2.45l-2.91 1.94v2.13zm0-3.879L14.87.486A.727.727 0 0116 1.09v8.727c0 .581-.647.928-1.13.605l-3.234-2.155v.82a1.821 1.821 0 01-1.817 1.821H1.817A1.818 1.818 0 010 9.088V1.821C0 .817.815 0 1.817 0h8.002c1.005 0 1.817.815 1.817 1.821v.82zm-10.181-.82v7.267c0 .204.162.367.362.367h8.002a.367.367 0 00.363-.367V1.821a.364.364 0 00-.363-.366H1.817a.367.367 0 00-.362.366z" _fill="#5E6385"/>'
  }
})
